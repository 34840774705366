<template>
  <div>
    <el-dialog
      class="custom-dialog pagetwo-dialog"
      :visible.sync="distrListDialogVisible"
    >
      <template v-slot:title>
        <h3>设备列表</h3>
      </template>
      <span class="u-font-38 blod u-p-l-36 white">设备信息</span>
      <div class="u-flex u-row-between u-p-20">
        <div class="u-flex">
          <el-select
            class="min-select"
            v-model="warnInfoValue1"
            placeholder="请选择"
            @change="getEquipmentList"
          >
            <el-option
              v-for="item in warnInfoOption1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            class="min-select u-m-l-30"
            v-model="warnInfoStatusValue"
            placeholder="请选择"
          >
            <el-option
              v-for="item in warnInfoStatusOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <el-input
          style="width: 200px"
          v-model="warnInfoSnValue"
          @change="getEquipmentList"
          placeholder="请输入您想查找的sn号"
        >
        </el-input>
        <!--      <div class="u-flex">
        <div class="btn btn-blue">单个导入</div>
        <div class="btn btn-blue u-m-l-20">批量导入</div>
      </div>-->
      </div>
      <el-table highlight-current-row :data="equipList" style="width: 100%">
        <el-table-column prop="ID" label="SN号"> </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            {{ scope.row.type.name }}
          </template>
        </el-table-column>
        <el-table-column prop="NAME" label="名称"> </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <!--          <i
            class="el-icon-success"
            :class="{
              cyan: scope.row.parentStatus == '0',
              red: scope.row.parentStatus == '1',
              yellow: scope.row.parentStatus == '2',
              gray: scope.row.parentStatus == '3',
            }"
          ></i>-->

            <i class="el-icon-success cyan">{{ scope.row.parentStatus }}</i>
          </template>
        </el-table-column>
        <el-table-column label="所属场站">
          <template slot-scope="scope">
            {{ scope.row.station.NAME }}
          </template></el-table-column
        >
        <!--      <el-table-column prop="parentType" label="父设备类型"> </el-table-column>
      <el-table-column prop="parentSn" label="父设备SN"> </el-table-column>
      <el-table-column prop="parentStatus" label="父设备状态">
        <template slot-scope="scope">
          <i
            class="el-icon-success"
            :class="{
              cyan: scope.row.parentStatus == '0',
              red: scope.row.parentStatus == '1',
              yellow: scope.row.parentStatus == '2',
              gray: scope.row.parentStatus == '3',
            }"
          ></i>
        </template>
      </el-table-column>-->
        <el-table-column prop="time" label="数据更新时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="u-flex btnbox u-row-center">
              <span
                @click="handleCurrentChange(scope.row)"
                style="cursor: pointer"
                >详情</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        class="u-m-t-20 u-flex u-row-right u-p-r-60"
        layout="total,prev, pager, next,jumper,slot"
        :page-size="page_size"
        @current-change="changePage"
        :total="total"
      >
        <div class="btn" @click="clickEnter()">确定</div>
      </el-pagination>
      <div class="off" @click="distrListDialogVisible = false"></div>
    </el-dialog>
    <EquipmentDetail @change="goPush" ref="detail"></EquipmentDetail>
  </div>
</template>

<script>
import { getEquipmentClass, getEquipmentList } from "@/api/screen2";
import EquipmentDetail from "@/components/EquipmentDetail";

export default {
  name: "EquipmentClass",
  components: { EquipmentDetail },
  data() {
    return {
      distrListDialogVisible: false, //配电列表弹窗
      server_id: this.$store.state.screen.server_id,
      page: 1,
      total: 0,
      page_size: 15,
      equipList: [],
      search: "", //搜索
      warnInfoStatusOption: [
        {
          value: "all",
          label: "状态",
        },
      ],
      warnInfoStatusValue: "all",
      warnInfoSnOption: [
        {
          value: "all",
          label: "全部",
        },
      ],

      warnInfoSnValue: "",
      warnInfoValue1: "",
      warnInfoOption1: [
        {
          value: "",
          label: "全部",
        },
      ],
    };
  },
  created() {
    this.getEquipmentClass();
    this.getEquipmentList();
  },
  methods: {
    goPush() {
      this.distrListDialogVisible = true;
    },
    changePage(page) {
      this.page = page;
      this.getEquipmentList();
    },
    getEquipmentList() {
      getEquipmentList({
        page: this.page,
        class: this.warnInfoValue1,
        search_key: this.warnInfoSnValue,
        server_id: this.server_id,
        page_size: this.page_size,
      }).then((res) => {
        console.log("list", res);
        this.equipList = res.data;
        this.total = res.meta.total;
      });
    },
    getEquipmentClass() {
      getEquipmentClass({
        server_id: this.server_id,
      }).then((res) => {
        res.data.map((item) => {
          this.warnInfoOption1.push({ value: item.id, label: item.name });
        });
      });
    },
    // 分页器点击确定跳转
    clickEnter() {
      this.$emit("changeCurrentPage", this.page);
    },
    // eslint-disable-next-line no-unused-vars
    handleCurrentChange(currentRow) {
      this.distrListDialogVisible = false;
      this.$refs.detail.open(currentRow.ID);

      // eslint-disable-next-line no-unused-vars
      /*  this.$nextTick(() => {
        getLine3("line2", data);
      });*/
    },
  },
};
</script>

<style scoped></style>
