<template>
  <div class="public-panel panelh5 u-m-t-40">
    <h2>电力监控</h2>
    <div
      v-html="svgData"
      @click="openSvg"
      style="
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    />
    <!-- 配电图弹窗 -->
    <el-dialog
      class="custom custom-dialog index-dialog"
      width="90%"
      :visible.sync="distributionDialogVisible"
    >
      <template v-slot:title>
        <h3>配电图</h3>
      </template>
      <div
        v-html="svgData"
        @click="openSvg"
        style="display: flex; align-items: center; justify-content: center"
      />
      <div class="off" @click="distributionDialogVisible = false"></div>
    </el-dialog>
  </div>
</template>

<script>
import { getVisions, getVisionData } from "@/api/screen2";

export default {
  name: "Monitor",
  data() {
    return {
      server_id: this.$store.state.screen.server_id,
      time: this.$store.state.screen.time,
      distributionDialogVisible: false,
      svgName: "",
      timer: "",
      svg: "",
      svgData: "",
    };
  },
  created() {
    this.getVisions();
    this.timer = setInterval(() => {
      this.getVisionData();
    }, 60000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getVisions() {
      getVisions({ server_id: this.server_id, typeId: 1 }).then((res) => {
        console.log(res);
        this.svgName = res.res[0].value;
        this.getVisionData();
      });
    },
    openSvg() {
      this.distributionDialogVisible = true;
    },
    getVisionData() {
      getVisionData({ server_id: this.server_id, svgName: this.svgName }).then(
        (res) => {
          console.log(res);
          this.svgData = res.res;

          this.$nextTick(() => {
            const svgs = document.getElementsByTagName("svg");
            console.log("svgs", svgs);
            for (var i = 0; i < svgs.length; i++) {
              if (svgs[i].getAttribute("vdfpath") !== null) {
                this.svg = svgs[i];
                if (i === 0) {
                  svgs[i].setAttribute("width", 800);
                  svgs[i].setAttribute("height", 200);
                }

                console.log(i, svgs[i]);
                console.log("svg", this.svg);
              }
            }
          });
        }
      );
    },
  },
};
</script>

<style scoped>
/deep/ .custom .el-dialog {
  width: 90% !important;
  height: 100% !important;
}
</style>
