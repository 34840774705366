<template>
  <div>
    <h2 class="u-flex u-row-between">
      <span>配电室概况</span>
      <div class="btn btn-blue" @click="showEquipment">更多信息</div>
    </h2>
    <div class="u-flex tab u-p-l-60 u-p-t-20">
      <template v-for="(item, index) in distributionList">
        <span
          :class="distributionTab == index ? 'active' : ''"
          @click="distributionTab = index"
          :key="index"
          >{{ item.name }}</span
        >
      </template>
    </div>
    <div class="u-flex u-flex-wrap u-p-l-50 u-m-t-20">
      <div class="item u-flex">
        <i class="icon icon1"></i>
        <div class="u-flex u-flex-col u-col-top u-font-26">
          <span>温度</span>
          <span
            ><i class="oswald u-font-50 blue">{{
              distributionList[distributionTab].temperature == "0.00"
                ? "--"
                : distributionList[distributionTab].temperature
            }}</i
            ><i>℃</i></span
          >
        </div>
      </div>
      <div class="item u-flex">
        <i class="icon icon2"></i>
        <div class="u-flex u-flex-col u-col-top u-font-26">
          <span>湿度</span>
          <span
            ><i class="oswald u-font-50 blue">{{
              distributionList[distributionTab].humidity == "0.00"
                ? "--"
                : distributionList[distributionTab].humidity
            }}</i
            ><i>RH</i></span
          >
        </div>
      </div>
      <div class="item u-flex">
        <i class="icon icon3"></i>
        <div class="u-flex u-flex-col u-col-top u-font-26">
          <span>今日用电</span>
          <span
            ><i class="oswald u-font-50 blue">{{
              distributionList[distributionTab].active_kwh
            }}</i
            ><i>kW.h</i></span
          >
        </div>
      </div>
      <div class="item u-flex">
        <i class="icon icon4"></i>
        <div class="u-flex u-flex-col u-col-top u-font-26">
          <span>有功功率</span>
          <span
            ><i class="oswald u-font-50 blue">{{
              distributionList[distributionTab].meritorious
            }}</i
            ><i>kW</i></span
          >
        </div>
      </div>
      <div class="item u-flex">
        <i class="icon icon5"></i>
        <div class="u-flex u-flex-col u-col-top u-font-26">
          <span>实时负荷</span>
          <span
            ><i class="oswald u-font-50 blue">{{
              distributionList[distributionTab].loadValue
            }}</i
            ><i>kVA</i></span
          >
        </div>
      </div>
      <div class="item u-flex">
        <i class="icon icon6"></i>
        <div class="u-flex u-flex-col u-col-top u-font-26">
          <span>无功功率</span>
          <span
            ><i class="oswald u-font-50 blue">{{
              distributionList[distributionTab].reactive_prower
            }}</i
            ><i>kvar</i></span
          >
        </div>
      </div>
    </div>

    <!-- 配电室列表弹窗 -->
    <EquipmentClass ref="EquipmentClass"></EquipmentClass>
    <!-- 配电室详情弹窗 -->
  </div>
</template>

<script>
/*import { getLine3 } from "@/assets/js/charts";*/
import { getPowerListDetail } from "@/api/screen2";
import EquipmentClass from "@/components/EquipmentClass";

export default {
  name: "Distribution",
  components: { EquipmentClass },
  methods: {
    showEquipment() {
      const equipment = this.$refs.EquipmentClass;
      equipment.distrListDialogVisible = true;
    },
    getPush() {
      this.distrInfoDialogVisible = false;
      this.distrListDialogVisible = true;
    },
    // 配电室切换定时器
    distributionSwitchTimer() {
      this.distributionTimer = setInterval(() => {
        if (this.distributionTab < this.distributionList.length) {
          this.distributionTab++;
        } else {
          this.distributionTab = 0;
        }
      }, 6000);
    },
    getPowerListDetail() {
      getPowerListDetail({ service_id: this.server_id }).then((res) => {
        this.distributionList = res.res;
      });
    },
  },
  created() {
    this.getPowerListDetail();
    this.distributionSwitchTimer();
    setInterval(() => {
      this.getPowerListDetail();
    }, this.insterval);
  },
  data() {
    return {
      server_id: this.$store.state.screen.server_id,
      insterval: this.$store.state.screen.time,
      distrInfoDialogVisible: false, //配电详情弹窗
      timer: "",
      paramsValue: "",
      distributionTimer: null,
      distributionTab: 0,
      distributionList: [],
    };
  },
};
</script>

<style scoped></style>
