<template>
  <el-dialog
    class="custom-dialog pagetwo-dialog"
    :visible.sync="distrInfoDialogVisible"
    :width="'90%'"
  >
    <template v-slot:title>
      <h3
        class="u-flex u-col-cneter u-row-between"
        style="width: 100%; padding-right: 1.2rem"
      >
        <span>设备详情</span>
        <div class="goPush" style="cursor: pointer" @click="goPush">返回</div>
      </h3>
    </template>
    <!-- 基础信息 -->
    <el-scrollbar style="height: 700px">
      <!-- 滚动条 -->
      <div v-for="item in detail.list_info" :key="item.name">
        <span class="u-font-38 blod cyan1">{{ item.name }}</span>
        <el-row :gutter="20" class="base-info u-m-t-10 u-m-b-40">
          <el-col :span="24">
            <div class="u-flex u-m-t-20 warp" style="flex-wrap: wrap">
              <span v-for="filed in item.filed" :key="filed.key"
                >{{ filed.name }}：{{ filed.value }}{{ filed.unit }}</span
              >
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 历史数据 -->
      <span class="u-font-38 blod cyan1">历史数据</span>
      <div class="u-rela u-m-t-10">
        <div class="u-flex u-row-right u-rela right-action">
          <div class="datetab u-flex">
            <span
              :class="historyTab == 0 ? 'active' : ''"
              @click="historyTabChage(0)"
              >日</span
            >
            <span
              :class="historyTab == 1 ? 'active' : ''"
              @click="historyTabChage(1)"
              >周</span
            >
            <span
              :class="historyTab == 2 ? 'active' : ''"
              @click="historyTabChage(2)"
              >月</span
            >
            <span
              :class="historyTab == 3 ? 'active' : ''"
              @click="historyTabChage(3)"
              >年</span
            >
            <!--            <span
              :class="historyTab == 4 ? 'active' : ''"
              @click="historyTabChage(4)"
              >总</span
            >-->
          </div>
          <el-date-picker
            v-if="historyTab == 0"
            class="u-m-l-30"
            v-model="historydate"
            value-format="yyyy-MM-dd 00:00:00"
            type="date"
            @change="getEquipmentEquipDay"
            placeholder="选择日期"
          >
          </el-date-picker>
          <el-date-picker
            v-if="historyTab == 1"
            class="u-m-l-30"
            v-model="historydate"
            format="yyyy 第 WW 周"
            value-format="yyyy-MM-dd 00:00:00"
            type="week"
            @change="getEquipmentEquipWeek"
            placeholder="选择日期"
          >
          </el-date-picker>
          <el-date-picker
            v-if="historyTab == 2"
            class="u-m-l-30"
            v-model="historydate"
            value-format="yyyy-MM-dd 00:00:00"
            type="month"
            @change="getEquipmentEquipMonth"
            placeholder="选择日期"
          >
          </el-date-picker>
          <el-date-picker
            v-if="historyTab == 3"
            class="u-m-l-30"
            v-model="historydate"
            value-format="yyyy-MM-dd 00:00:00"
            type="year"
            @change="getEquipmentEquipYear"
            placeholder="选择日期"
          >
          </el-date-picker>
          <el-button
            v-if="historyTab == 0 || historyTab == 1"
            class="btn btn-blue u-m-l-30"
            @click="paramsPopShow = !paramsPopShow"
            >参数选择
          </el-button>
          <el-button class="btn btn-blue u-m-l-20" @click="exportExcel"
            >导出报表
          </el-button>
        </div>
        <div v-if="paramsPopShow" class="u-abso params-pop">
          <el-select v-model="paramsValue" placeholder="通过参数名称进行查询">
            <el-option
              v-for="item in paramsOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="wrap">
            <div class="u-flex select-wrap">
              <span class="label">遥测：</span>
              <div class="u-flex-1 u-p-t-10 u-p-b-10">
                <el-checkbox-group v-model="analog">
                  <el-checkbox
                    v-for="filed in equipParameter[0].filed"
                    :key="filed.key"
                    :label="filed.key"
                    :value="filed.key"
                  >
                    {{ filed.name }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="u-flex select-wrap">
              <span class="label">遥脉：</span>
              <div class="u-flex-1 u-p-t-10 u-p-b-10">
                <el-checkbox-group v-model="acc">
                  <el-checkbox
                    v-for="filed in equipParameter[1].filed"
                    :key="filed.key"
                    :label="filed.key"
                    :value="filed.key"
                  >
                    {{ filed.name }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="u-flex u-row-right u-p-t-28 u-p-b-28 u-p-r-30">
              <el-button size="small" @click="templatePopShow = true"
                >保存为模板
              </el-button>
              <el-button size="small" @click="clearParam">清空选择</el-button>
              <el-button size="small" @click="sureParam">确定 </el-button>
            </div>
          </div>
        </div>
        <!--保存为模板弹窗-->
        <div v-if="templatePopShow" class="u-abso params-pop">
          <div class="templateWorp">
            <div class="title">
              <h5>保存未自定义模板</h5>
              <img
                @click="templatePopShow = false"
                src="~@/assets/images/errorIcon.png"
                alt=""
              />
            </div>
            <div class="inputField">
              <input
                type="text"
                v-model="tpl_name"
                placeholder="请输入模板名称"
              />
            </div>
            <div class="operate">
              <div
                class="call-off"
                style="cursor: pointer"
                @click="templatePopShow = false"
              >
                取消
              </div>
              <div
                class="call-off call-off1"
                style="cursor: pointer"
                @click="setChartsTemplate"
              >
                保存
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="u-m-t-36 u-flex system-model u-font-28"
        v-if="historyTab == 0 || historyTab == 1"
      >
        <div style="width: 130px" class="white">系统模块：</div>
        <span
          v-for="(item, index) in systemTpl"
          :key="index"
          class="item u-m-l-36"
          :class="systemTab == index ? 'active' : ''"
          @click="handleSystemTab(index)"
          >{{ item.name }}</span
        >
        <!-- <span
        class="u-m-l-32 blue how"
        :class="systemTab == 4 ? 'active' : ''"
        @click="handleSystemTab(4)"
        >如何创建我的模板？</span
      > -->
      </div>
      <div
        class="u-m-t-36 u-flex system-model u-font-28"
        v-if="custom.length > 0 && (historyTab == 0 || historyTab == 1)"
      >
        <div style="width: 130px" class="white">自定义模板：</div>
        <span
          v-for="(item, index) in custom"
          :key="index"
          class="item u-m-l-36"
          :class="customTab == index ? 'active' : ''"
          @click="handleCustomTab(index)"
          >{{ item.name }}</span
        >
        <!-- <span
        class="u-m-l-32 blue how"
        :class="systemTab == 4 ? 'active' : ''"
        @click="handleSystemTab(4)"
        >如何创建我的模板？</span
      > -->
      </div>
      <div class="chart u-m-b-36" id="line2"></div>
      <!-- 报警信息 -->
      <span class="u-font-38 blod cyan1">报警信息</span>
      <div class="u-flex u-m-t-20">
        <el-button class="btn btn-blue">全部</el-button>
        <el-button class="btn btn-blue u-m-l-20">发生中</el-button>
        <el-button class="btn btn-blue u-m-l-20">已恢复</el-button>
      </div>
      <el-table :data="warnInfoList" style="width: 100%">
        <el-table-column prop="name" label="信息名称"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间"></el-table-column>
        <el-table-column prop="reason" label="报警原因"></el-table-column>
      </el-table>
    </el-scrollbar>
    <div class="off" @click="distrInfoDialogVisible = false"></div>
  </el-dialog>
</template>

<script>
import { getLine3, getLine4 } from "@/assets/js/charts";
import * as dayjs from "dayjs";
import {
  getEquipmentDetail,
  getEquipmentEquipDay,
  getEquipParameter,
  getEquipmentEquipWeek,
  getEquipmentEquipMonth,
  getEquipmentEquipYear,
  getChartsTemplate,
  setChartsTemplate,
} from "@/api/screen2";
export default {
  name: "EquipmentDetail",
  data() {
    return {
      paramsPopShow: false,
      templatePopShow: false, //保存为模板弹窗
      distrInfoDialogVisible: false,
      historyTab: 0,
      id: "",
      systemTpl: [],
      custom: [],
      customTab: 10000,
      historydateWeek: new Date(),
      paramsOption: [
        {
          value: "all",
          label: "全部",
        },
      ],
      telemeterList: ["选中且禁用", "复选框 A"], //遥测复选框
      remotePulseList: ["选中且禁用", "复选框 A"],
      paramsValue: "",
      tpl_name: "",
      server_id: this.$store.state.screen.server_id,
      historydate: new Date(),
      systemTab: 0,
      detail: {},
      warnInfoList: [],
      analog: [],
      acc: [],
      equipParameter: [],
    };
  },

  methods: {
    getEquipParameter() {
      getEquipParameter({ id: this.id, server_id: this.server_id }).then(
        (res) => {
          this.equipParameter = res.data;
        }
      );
    },
    handleCustomTab(index) {
      this.customTab = index;
      this.analog = this.custom[index].setting.analog.map((item) => {
        return parseInt(item);
      });
      this.acc = this.custom[index].setting.acc.map((item) => {
        return parseInt(item);
      });
      this.systemTab = 10000;

      this.requestApi();
    },
    exportExcel() {
      let type = "day";
      switch (this.historyTab) {
        case 0:
          type = "day";
          break;
        case 1:
          type = "week";
          break;
        case 2:
          type = "month";
          break;

        case 3:
          type = "year";
          break;
      }
      let time = dayjs(this.historydate).format("YYYY-MM-DD HH:mm:ss");
      time = encodeURIComponent(time);
      console.log("time", time);
      window.open(
        // eslint-disable-next-line no-undef
        PLATFROM_CONFIG.baseUrl +
          "/api/v2/exportEquipmentReport?server_id=" +
          this.server_id +
          "&id=" +
          this.id +
          "&time=" +
          time +
          "&type=" +
          type
      );
    },
    getChartsTemplate() {
      getChartsTemplate({ id: this.id, server_id: this.server_id }).then(
        (res) => {
          console.log(res);
          this.systemTpl = res.res.system;
          this.analog = res.res.system[0].setting.analog.map((item) => {
            return parseInt(item);
          });
          this.acc = res.res.system[0].setting.acc.map((item) => {
            return parseInt(item);
          });
          this.custom = res.res.custom;
          this.requestApi();
        }
      );
    },
    //设置模板
    setChartsTemplate() {
      if (!this.tpl_name) {
        alert("请填写模板名称");
        return;
      }
      if (this.analog.length + this.acc.length == 0) {
        alert("请选择参数");
        return;
      }
      let number = this.custom.push({
        name: this.tpl_name,
        setting: {
          analog: this.analog,
          acc: this.acc,
        },
      });
      this.paramsPopShow = false;
      this.templatePopShow = false;
      this.handleCustomTab(number - 1);

      setChartsTemplate({
        id: this.id,
        server_id: this.server_id,
        analog: this.analog,
        name: this.tpl_name,
        acc: this.acc,
      }).then((res) => {
        console.log(res);
      });
    },
    requestApi() {
      if (this.historyTab == 0) {
        //日
        this.getEquipmentEquipDay();
      } else if (this.historyTab == 1) {
        this.getEquipmentEquipWeek();
      } else if (this.historyTab == 2) {
        this.getEquipmentEquipMonth();
      } else if (this.historyTab == 3) {
        this.getEquipmentEquipYear();
      }
    },
    getEquipmentEquipDay() {
      getEquipmentEquipDay({
        server_id: this.server_id,
        time: this.historydate,
        id: this.id,
        analog: this.analog,
        acc: this.acc,
      }).then((res) => {
        console.log(res);
        let data = res.res;
        this.$nextTick(() => {
          getLine3("line2", data);
        });
      });
    },
    getEquipmentEquipMonth() {
      getEquipmentEquipMonth({
        server_id: this.server_id,
        time: this.historydate,
        id: this.id,
        analog: this.analog,
        acc: this.acc,
      }).then((res) => {
        console.log(res);
        let data = res.res;
        this.$nextTick(() => {
          getLine4("line2", data);
        });
      });
    },
    getEquipmentEquipYear() {
      getEquipmentEquipYear({
        server_id: this.server_id,
        time: this.historydate,
        id: this.id,
        analog: this.analog,
        acc: this.acc,
      }).then((res) => {
        console.log(res);
        let data = res.res;
        this.$nextTick(() => {
          getLine4("line2", data);
        });
      });
    },
    getEquipmentEquipWeek() {
      getEquipmentEquipWeek({
        server_id: this.server_id,
        time: this.historydate,
        id: this.id,
        analog: this.analog,
        acc: this.acc,
      }).then((res) => {
        console.log(res);
        let data = res.res;
        this.$nextTick(() => {
          getLine3("line2", data);
        });
      });
    },
    historyTabChage(tap_id) {
      if (this.historyTab !== tap_id) {
        this.historyTab = tap_id;
        this.requestApi();
      }
    },
    open(id) {
      console.log(id);
      this.id = id;
      this.distrInfoDialogVisible = true;
      this.systemTab = 0;
      this.custom = 10000;
      this.getEquipmentDetail();
      this.getEquipParameter();
      this.getChartsTemplate();
    },
    getEquipmentDetail() {
      getEquipmentDetail({ id: this.id, server_id: this.server_id }).then(
        (res) => {
          this.detail = res.data;
        }
      );
    },
    // 系统模块切换
    handleSystemTab(index) {
      this.systemTab = index;
      this.customTab = 10000;
      this.analog = this.systemTpl[index].setting.analog;
      this.acc = this.systemTpl[index].setting.acc;
      this.requestApi();
    },
    //清空选择
    clearParam() {
      this.analog = [];
      this.acc = [];
    },
    goPush() {
      this.distrInfoDialogVisible = false;
      this.$emit("change");
    },
    //
    sureParam() {
      this.paramsPopShow = false;

      if (this.historyTab == 0) {
        //日
        this.getEquipmentEquipDay();
      } else if (this.historyTab == 1) {
        this.getEquipmentEquipWeek();
      }
    },
  },
};
</script>

<style scoped>
/deep/ .pagetwo-page .pagetwo-dialog .el-dialog {
  width: 40.51rem !important;
}
</style>
