<template>
  <video
    ref="video"
    muted
    controls="controls"
    style="
      /*生成绝对定位的元素，相对于 static 定位以外的第一个父元素进行定位。可以根据实际情况修改*/
      position: absolute;
      /*这里我试了一下好像只要是矩形的四个角的的点都行,也就是说right:0;bottom:0;也可以*/
      left: 0;
      top: 0;
      /*将视频放在元素的堆叠顺序最底层，以防覆盖其他东西*/
      z-index: -9999;
      /*被替换的内容正好填充元素的内容框。整个对象将完全填充此框。如果对象的宽高比与内容框不相匹配，那么该对象将被拉伸以适应内容框。*/
      object-fit: fill;
    "
  />
</template>

<script>
export default {
  name: "custVideo",
  props: ["videourl"],
  watch: {
    videourl: {
      handler(newVal, oldVal) {
        console.log("videourlnew", newVal);
        console.log("videourl", oldVal);
        if (newVal) {
          this.destroyHls();
          this.player();
        }
      },
    },
  },
  data() {
    return {
      hls: "",
    };
  },
  mounted() {
    this.destroyHls();
    // this.player();
  },
  methods: {
    player() {
      const video = this.$refs.video;
      // eslint-disable-next-line no-undef
      if (Hls.isSupported()) {
        this.destroyHls();
        // eslint-disable-next-line no-undef
        this.hls = new Hls();
        this.hls.loadSource(this.videourl);
        this.hls.attachMedia(video);
        // eslint-disable-next-line no-undef
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = this.videourl;
        video.addEventListener("loadedmetadata", function () {
          video.play();
        });
      }
    },

    destroyHls: function () {
      const video = this.$refs.video;
      if (this.hls) {
        console.log("已销毁");
        video.pause();
        this.hls.destroy();
        this.hls = null;
      }
    },
  },
  destroyed() {
    this.destroyHls();
  },
};
</script>

<style scoped></style>
