<template>
  <div>
    <Head></Head>

    <div class="pagetwo-page publicpage u-flex u-col-top u-row-between">
      <div class="page-right">
        <div class="public-panel distribution">
          <Distribution></Distribution>
        </div>
        <div class="public-panel u-m-t-30">
          <Load></Load>
        </div>
        <div class="public-panel u-m-t-30">
          <ServiceTransformerList></ServiceTransformerList>
        </div>
      </div>
      <div class="page-middle">
        <div class="mdtop u-rela">
          <Turntable></Turntable>
        </div>
        <Site></Site>
        <Monitor></Monitor>
      </div>
      <div class="page-left">
        <!--左一----巡检记录 -->
        <Task></Task>
        <!--左二----报警汇总 -->
        <Alarm></Alarm>
        <!--左三----视频 -->
        <videos></videos>
      </div>
    </div>
  </div>
</template>

<script>
//import vueSeamlessScroll from "vue-seamless-scroll";
import ServiceTransformerList from "@/views/pageTwo/components/ServiceTransformerList";
import Turntable from "@/views/pageTwo/components/Turntable";
import Distribution from "@/views/pageTwo/components/Distribution";
import Load from "@/views/pageTwo/components/Load";
import Site from "@/views/pageTwo/components/Site";
import Head from "@/views/pageTwo/components/Head";
import Task from "@/components/Task";
import Alarm from "@/components/Alarm";
import Monitor from "@/views/pageTwo/components/Monitor";
import Videos from "@/views/pageTwo/components/Videos";
export default {
  name: "",
  data() {
    return {
      distributionDialogVisible: false, //配电图弹窗
      paramsOption: [
        {
          value: "all",
          label: "全部",
        },
      ],
    };
  },
  components: {
    Videos,
    Monitor,
    Alarm,
    Task,
    Head,
    Site,
    Load,
    Distribution,
    Turntable,
    ServiceTransformerList,
    //vueSeamlessScroll,
  },
  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  beforeCreate() {
    document.title = this.$store.state.screen.title;
  },
  methods: {},
  beforeDestroy() {
    clearInterval(this.distributionTimer);
    this.timer = null;
  },
};
</script>
