<template>
  <div class="public-panel">
    <h2>巡检记录</h2>
    <table class="table1">
      <colgroup>
        <col width="33%" />
        <col width="33%" />
        <col width="33%" />
      </colgroup>
      <tr>
        <th>站点</th>
        <th>维护人员</th>
        <th>巡检</th>
      </tr>
    </table>
    <div class="table-body">
      <vue-seamless-scroll
        :data="inspectionRecordList"
        :class-option="defaultOption"
      >
        <table class="table1">
          <colgroup>
            <col width="33%" />
            <col width="33%" />
            <col width="33%" />
          </colgroup>
          <tr v-for="(item, index) in inspectionRecordList" :key="index">
            <td>{{ item.title }}</td>
            <td class="cyan">{{ item.name }}</td>
            <td :class="{ red: item.is_clock == 0 }">
              {{ item.is_clock == 0 ? "未巡检" : "已巡检" }}
            </td>
          </tr>
        </table>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { getInspections } from "@/api/screen2";
export default {
  name: "Task",
  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  components: {
    vueSeamlessScroll,
  },
  created() {
    this.getInspections();
    this.timer = setInterval(() => {
      this.getInspections();
    }, this.time);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getInspections() {
      getInspections({}).then((res) => {
        this.inspectionRecordList = res.res;
      });
    },
  },
  data() {
    return {
      timer: "",
      time: this.$store.state.screen.time,
      server_id: this.$store.state.screen.server_id,
      inspectionRecordList: [
        {
          title: "老厂房",
          name: "李许强",
          is_clock: 0,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
