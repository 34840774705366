<template>
  <div>
    <header class="u-flex u-row-center u-rela">
      <i class="icon-logo shou" @change="goto"></i>
      <span class="title u-m-l-26 shou" @click="goto">{{ title }}</span>
      <div class="left u-font-32 u-flex u-abso">
        <div
          class="icon-wea"
          style="visibility: hidden"
          v-if="weatherType === 1"
        ></div>
        <div class="tianqi" style="visibility: hidden" v-else></div>
        <span class="u-m-l-12">{{ weather }}</span>
        <div class="line"></div>
        <span>安全运行天数：</span
        ><i class="oswald u-font-50 blue u-m-r-10">{{ safe_day }}</i
        ><span class="u-font-24">天</span>
      </div>
      <div class="right u-font-32 u-flex u-abso">
        <i class="icon-time"></i>
        <span class="u-m-l-10">{{ nowDate }}</span>
        <i class="line"></i>
        <span class="oswald u-font-50 blue u-m-r-10"> {{ nowTime }} </span>
        <i class="line"></i>
        <span
          class="icon-msg u-flex u-flex-col u-row-center"
          @click="showAlarm"
        >
          <i class="msg"></i>
          <span class="u-m-t-4">消息</span>

          <!-- 最新报警newAlarm 弹窗 -->
        </span>
      </div>
    </header>
    <router-view class="page-wrap" />
    <alarm-dialog ref="alarm"></alarm-dialog>
  </div>
</template>

<script>
import { getSafeDays, getWeather } from "@/api/screen";
import AlarmDialog from "@/components/AlarmDialog";

export default {
  name: "Head",
  components: { AlarmDialog },
  data() {
    return {
      title: this.$store.state.screen.title,
      server_id: this.$store.state.screen.server_id,
      safe_day: 0,
      weather: "",
      nowDate: null, //存放年月日变量
      nowTime: null, //存放时分秒变量
      timer: "", //定义一个定时器的变量
      weatherType: 1,
      currentTime: new Date(), // 获取当前时间

      project_id: 1,
    };
  },
  created() {
    this.timer = setInterval(this.getTime, 1000);
    this.getName();
    this.getSafeDays();
    this.getWeather();
    setInterval(() => {
      this.getWeather();
    }, 3600000);
  },
  methods: {
    getWeather() {
      getWeather({}).then((res) => {
        if (res.res.value.indexOf("晴") !== -1) {
          this.weatherType = 1;
        } else {
          this.weatherType = 2;
        }
        this.weather = res.res.value;
      });
    },
    getSafeDays() {
      getSafeDays({ server_id: this.server_id }).then((res) => {
        this.safe_day = res.res.safe_days;
      });
    },
    showAlarm() {
      this.$refs.alarm.newAlarmDialog = true;
    },
    getName(name) {
      if (name == "可疑") {
        return "yellow1";
      } else if (name == "一般") {
        return "orange";
      } else if (name == "致命") {
        return "red";
      } else if (name == "操作") {
        return "violet";
      }
    },
    //时间加载
    getTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();

      if (this.hour > 12) {
        this.hour -= 12;
        this.str = " AM";
      }
      this.month = check(month);
      this.day = check(day);
      this.hour = check(hour);
      this.minute = check(minute);
      this.second = check(second);
      function check(i) {
        const num = i < 10 ? "0" + i : i;
        return num;
      }
      this.nowDate = year + "年" + this.month + "月" + this.day + "日";
      this.nowTime = this.hour + ":" + this.minute + ":" + this.second;
    },
    goto() {
      window.location.href = window.PLATFROM_CONFIG.baseUrl1;
    },
    //点击消息弹窗
    showlod() {
      console.log(111);
    },
  },
};
</script>
<style lang="scss" scoped>
.table1 {
  tr {
    height: 0.7rem;
    background: #011760;
    text-align: center;
    font-size: 0.22rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  tr:nth-of-type(odd) {
    background: rgba(0, 0, 0, 0.1);
  }
  .shou {
    cursor: pointer;
  }
  .title {
    height: 0.7rem;
    background: #0f2368 !important;
    font-size: 0.28rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #009cff;
  }
}
</style>
