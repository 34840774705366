<template>
  <div>
    <h2>变压器概况</h2>
    <table class="table1">
      <colgroup>
        <col width="25%" />
        <col width="25%" />
        <col width="25%" />
        <col width="25%" />
      </colgroup>
      <tr>
        <th>名称</th>
        <th>电流</th>
        <th>负荷</th>
        <th>负荷率</th>
      </tr>
    </table>
    <div class="table-body">
      <vue-seamless-scroll
        :data="transformerList"
        :class-option="defaultOption"
      >
        <table class="table1">
          <colgroup>
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
          </colgroup>
          <tr v-for="(item, index) in transformerList" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.lower_a }}A</td>
            <td>{{ item.apparent_power }}kVA</td>
            <td :class="onColor(item.load_rate)">{{ item.load_rate }}%</td>
          </tr>
        </table>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { getServiceTransformerList } from "@/api/screen2";

export default {
  name: "ServiceTransformerList",

  mounted() {
    this.onColor();
  },
  components: {
    vueSeamlessScroll,
  },
  created() {
    this.getServiceTransformerList();
    this.time = setInterval(() => {
      this.getServiceTransformerList();
    }, this.$store.state.screen.time);
  },
  destroyed() {
    clearInterval(this.time);
  },
  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    getServiceTransformerList() {
      getServiceTransformerList({ service_id: this.server_id }).then((res) => {
        this.transformerList = res.res;
      });
    },
    //表格颜色
    onColor(name) {
      if (name < 40) {
        return "orange";
      } else if (name > 70) {
        return "purple";
      } else if (name >= 40 && name <= 70) {
        return "yellow1";
      }
    },
  },
  data() {
    return {
      time: "",
      server_id: this.$store.state.screen.server_id,
      transformerList: [
        {
          name: "lat1",
          lower_a: "1411.32",
          apparent_power: "",
          load_rate: "33",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
