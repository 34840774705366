<template>
  <div>
    <h2>今日负荷趋势</h2>
    <div class="chart" id="line1" style="height: 230px"></div>
  </div>
</template>

<script>
import { line2Option } from "@/assets/js/charts";
import { getBigDataLoadV1 } from "@/api/screen2";
import * as echarts from "echarts";
import tools from "@/unit/autotips";
export default {
  name: "Load",
  data() {
    return {
      tool: [],
      time: this.$store.state.screen.time,
      server_id: this.$store.state.screen.server_id,
    };
  },
  mounted() {
    //getLine2("line1");
  },
  created() {
    this.getBigDataLoadV1();
  },
  methods: {
    getBigDataLoadV1() {
      getBigDataLoadV1({ server_id: this.server_id }).then((res) => {
        this.$nextTick(() => {
          console.log(res);
          let myChart = echarts.init(document.getElementById("line1"));
          line2Option.legend.title = res.res.titles;
          line2Option.series[0].name = res.res.titles[0];
          line2Option.series[0].data = res.res.datas[0];
          console.log(line2Option);
          myChart.setOption(line2Option);
          if (this.tool["line1"] !== undefined) {
            this.tool["line1"].clearLoop();
          }
          const tool = tools.loopShowTooltip(myChart, line2Option, {
            loopSeries: true,
          });
          this.tool["line1"] = tool;
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        });
      });
    },
  },
};
</script>

<style scoped></style>
