<template>
  <div class="public-panel panelh4 u-flex u-row-around">
    <div class="item u-flex" v-for="(item, index) in numList" :key="index">
      <div class="left u-flex u-row-center">
        <i
          :class="'icon' + index"
          class="animate__animated animate__pulse animate__infinite"
        ></i>
      </div>
      <div class="right u-flex u-flex-col u-row-center">
        <span class="u-font-26">{{ item.name }}</span>
        <span class="oswald u-font-40 blue">{{ item.num }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getBigDataMsgs } from "@/api/screen";

export default {
  name: "Site",
  data() {
    return {
      server_id: this.$store.state.screen.server_id,

      numList: [
        {
          name: "站点个数",
          num: 5,
        },
        {
          name: "设备总数",
          num: 154,
        },
        {
          name: "测点总数",
          num: 2653,
        },
        {
          name: "管理人员",
          num: 55,
        },
        {
          name: "运维工程师",
          num: 401,
        },
      ],
    };
  },
  created() {
    this.getBigDataMsgs();
  },
  methods: {
    getBigDataMsgs() {
      getBigDataMsgs({ server_id: this.server_id }).then((res) => {
        this.numList = res.res;
      });
    },
  },
};
</script>

<style scoped></style>
