<template>
  <div class="public-panel u-m-t-30">
    <h2>报警汇总</h2>
    <div class="chart" id="pie1"></div>
  </div>
</template>

<script>
import { getHollowPie } from "@/assets/js/charts";
import { getBigDataAlarmnNum } from "@/api/screen";

export default {
  name: "Alarm",
  data() {
    return {
      time: this.$store.state.screen.time,
      timer: "",
      server_id: this.$store.state.screen.server_id,
    };
  },
  created() {
    this.getBigDataAlarmnNum();
    this.timer = setInterval(() => {
      this.getBigDataAlarmnNum();
    }, this.time);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getBigDataAlarmnNum() {
      getBigDataAlarmnNum({ server_id: this.server_id }).then((res) => {
        let dataValue = res.res;
        this.$nextTick(() => {
          getHollowPie("pie1", dataValue);
        });
      });
    },
  },
};
</script>

<style scoped></style>
